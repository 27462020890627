<template>
  <div class="benefits text-center text-white pt-111">
    <h1 data-test="benefits-title" class="title text-42 mb-15">{{ title }}</h1>
    <h3 class="benefits-text text-20 mb-114">{{ text }}</h3>
    <div class="benefits-wrapper mx-auto px-100">
      <benefits-items :cards="children" :is-vertical="false" theme="dark" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Benefits',
  components: {
    BenefitsItems: () => import('@/components/BenefitsItems.vue'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    children: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.benefits {
  .benefits-wrapper {
    justify-content: space-evenly;
  }
}
</style>
